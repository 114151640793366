<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
      :width="30"
      :height="30"
    ></loading>

    <b-alert variant="warning" v-if="data == null" show class="mb-0"
      >Мэдээлэл байхгүй байна</b-alert
    >

    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Төлөв</label>
                <select class="form-control" v-model="status">
                  <option
                    :value="value.id"
                    v-for="(value, index) in status_list"
                    :key="index"
                    >{{ value.name}}</option
                  >
                </select>
              </div>
<!--              <div class="form-group" v-if="status == 'UN_CONFIRM'">-->
<!--                <label for="">Бусад</label>-->
<!--                {{ un_confirm_other }}-->
<!--                <select class="form-control" v-model="un_confirm_other">-->
<!--                  <option-->
<!--                    :value="item.id"-->
<!--                    v-for="(item, index) in un_confirm_other_list"-->
<!--                    :key="index"-->
<!--                    >{{ item.name }}</option-->
<!--                  >-->
<!--                </select>-->
<!--              </div>-->
              <div class="form-group">
                <label for="">Тайлбар</label>
                <textarea class="form-control" v-model="description"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" @click="changeStatus">
                  Хадгалах
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6">
          <div class="row mb-0">
            <div class="col-md-6">
              <b-card>
                <loading
                  :active.sync="isLoadingFile"
                  :is-full-page="false"
                  :color="this.$config.LOADER.color"
                  :opacity="this.$config.LOADER.opacity"
                  :background-color="this.$config.LOADER.backgroundColor"
                  :z-index="10000"
                  :width="30"
                  :height="30"
                ></loading>
                <div class="form-group">
                  <label for="">Ярилцлагын тэмдэглэл</label>
                  <b-form-file
                    v-model="file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary" @click="uploadMeetNote">
                    Хадгадах
                  </button>
                  <a
                    :href="file_link"
                    v-if="file_link"
                    class="pull-right btn btn-info"
                    >Файл татах</a
                  >
                </div>
                <div class="form-group mb-0  mt-3" v-if="file_message">
                  <b-alert class="mb-0" show :variant="file_message.variant">{{
                    file_message.text
                  }}</b-alert>
                </div>
              </b-card>
            </div>
          </div>
        </div> -->
      </div>
      <hr />
      <b-card>
        <template #header>
          <h4 class="mb-0">{{data.type_name}}ийн хүсэлт /{{data.status_name }}/</h4>
        </template>
        <b-card-body>
          <b-form>
            <b-card-title>Зээлийн хүсэлт илгээгчийн мэдээлэл</b-card-title>
            <hr class="my-4">
            <b-row>
              <b-col md="4">
                <b-form-group label="Овог:" label-for="input-change-lastname">
                  <b-form-input
                      id="input-change-lastname"
                      v-model="data.lastname"
                      required
                      placeholder="Овог"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Нэр:" label-for="input-change-firstname">
                  <b-form-input
                      id="input-change-firstname"
                      v-model="data.firstname"
                      required
                      placeholder="Нэр"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Регистер:" label-for="input-change-register_number">
                  <b-form-input
                      id="input-change-register_number"
                      v-model="data.register_number"
                      required
                      placeholder="Нэр"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Утас: " label-for="input-change-phone1">
                  <b-form-input
                      maxlength="8"
                      pattern="\d{8}"
                      id="input-change-phone1"
                      v-model="data.phone_number"
                      required
                      placeholder="Утас"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Имэйл:" label-for="input-change-email">
                  <b-form-input
                      type="text"
                      pattern="([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})"
                      id="input-change-email"
                      v-model="data.email"
                      placeholder="Имэйл"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Хаяг:" label-for="input-change-address">
                  <b-form-textarea
                      type="text"
                      id="input-change-description"
                      v-model="data.address"
                      placeholder="Хаяг"
                      disabled
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group label="Боловсрол:" label-for="input-change-education">
                  <b-form-input
                      id="input-change-education"
                      v-model="data.education_name"
                      required
                      placeholder="Овог"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Туршлага:" label-for="input-change-experience">
                  <b-form-input
                      id="input-change-experience"
                      v-model="data.experience_name"
                      required
                      placeholder="Нэр"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Гэрлэлтийн байдал:" label-for="input-change-family_state">
                  <b-form-input
                      id="input-change-family_state"
                      v-model="data.family_state_name"
                      required
                      placeholder="Нэр"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group label="Ажлын байрны чиглэл: " label-for="input-change-work_route">
                  <b-form-input
                      maxlength="8"
                      pattern="\d{8}"
                      id="input-change-work_route"
                      v-model="data.work_route_name"
                      required
                      placeholder="Ажлын байрны чиглэл"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Ажлын байрны салбар:" label-for="input-change-work_sector">
                  <b-form-input
                      maxlength="8"
                      pattern="\d{8}"
                      id="input-change-work_sector"
                      v-model="data.work_sector_name"
                      required
                      placeholder="Ажлын байрны салбар"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Албан тушаал:" label-for="input-change-position">
                  <b-form-input
                      maxlength="8"
                      pattern="\d{8}"
                      id="input-change-work_sector"
                      v-model="data.position_name"
                      required
                      placeholder="Албан тушаал"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <br>
            <b-card-title>Зээлийн хүсэлтийн мэдээлэл</b-card-title>
            <hr class="my-4">
            <b-row v-if="data.loanApplicationInfo">
              <b-col md="4">
                <b-form-group label="Нийт дүн:" label-for="input-change-product_amount">
                  <money
                      id="input-change-product_amount"
                      class="form-control"
                      v-model="data.loanApplicationInfo.product_amount"
                      type="text"
                      placeholder=""
                      suffix=" ₮"
                      :precision="2"
                      disabled
                  ></money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Урьдчилгаа:" label-for="input-change-pre_amount">
                  <money
                      id="input-change-pre_amount"
                      class="form-control"
                      v-model="data.loanApplicationInfo.pre_amount"
                      type="text"
                      placeholder=""
                      suffix=" ₮"
                      :precision="2"
                      disabled
                  ></money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Зээлийн дүн:" label-for="input-change-loan_amount">
                  <money
                      id="input-change-pre_amount"
                      class="form-control"
                      v-model="data.loanApplicationInfo.loan_amount"
                      type="text"
                      placeholder=""
                      suffix=" ₮"
                      :precision="2"
                      disabled
                  ></money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Хугацаа:" label-for="input-change-term">
                  <money
                      id="input-change-pre_amount"
                      class="form-control"
                      v-model="data.loanApplicationInfo.term"
                      type="text"
                      placeholder=""
                      suffix=" "
                      :precision="2"
                      disabled
                  ></money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Хүү:" label-for="input-change-interest">
                  <money
                      id="input-change-interest"
                      class="form-control"
                      v-model="data.loanApplicationInfo.interest"
                      type="text"
                      placeholder=""
                      suffix=" "
                      :precision="2"
                      disabled
                  ></money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Сарын төлөлт:" label-for="input-change-monthly_amount">
                  <money
                      id="input-change-monthly_amount"
                      class="form-control"
                      v-model="data.loanApplicationInfo.monthly_amount"
                      type="text"
                      placeholder=""
                      suffix=" ₮"
                      :precision="2"
                      disabled
                  ></money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                  <b-form-checkbox v-model="data.loanApplicationInfo.is_before_taken_loan" name="check-button" disabled switch id="input-change-before_loan">
                    Өмнө нь зээлтэй байсан эсэх: <b>({{ data.loanApplicationInfo.is_before_taken_loan ? 'ТИЙМ': 'ҮГҮЙ' }})</b>
                  </b-form-checkbox>
              </b-col>
              <b-col md="4">
                <b-form-checkbox v-model="data.loanApplicationInfo.is_ipotek" name="check-button" disabled switch id="input-change-is_ipotek">
                  Ипотекийн зээлтэй эсэх: <b>({{ data.loanApplicationInfo.is_ipotek ? 'ТИЙМ': 'ҮГҮЙ' }})</b>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
            <br>
            <b-card-title>Орлого нотлох баримт</b-card-title>
            <hr class="my-4">
            <b-row v-if="data.loanApplicationAttachment">
              <b-col md="4">
                <b-form-group label="Орлого нотлох байдал: " label-for="input-change-attachment_type">
                  <b-form-input
                      maxlength="8"
                      pattern="\d{8}"
                      id="input-change-work_route"
                      v-model="data.loanApplicationAttachment.attachment_type_name"
                      required
                      placeholder="Орлого нотлох байдал"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Орлого нотлох баримт:" label-for="input-change-attachment_file">
                  <b-input-group>

                    <b-form-input
                        id="input-change-attachment_file"
                        v-model="data.loanApplicationAttachment.attachment_file"
                        disabled
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="downloadFile(data.loanApplicationAttachment.attachment_file)">Татах</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Тайлбар:" label-for="input-change-attachment_desc">
                  <b-form-input
                      maxlength="8"
                      pattern="\d{8}"
                      id="input-change-attachment_desc"
                      v-model="data.loanApplicationAttachment.description"
                      required
                      placeholder="Тайлбар"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
            <br>
            <b-card-title>Хамтран зээлдэгч</b-card-title>
            <hr class="my-4">
            <b-row v-if="data.loanApplicationRelation">
              <b-col md="4">
                <b-form-group label="Овог:" label-for="input-change-relation_lastname">
                  <b-form-input
                      id="input-change-relation_lastname"
                      v-model="data.loanApplicationRelation.lastname"
                      required
                      placeholder="Овог"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Нэр:" label-for="input-change-relation_firstname">
                  <b-form-input
                      id="input-change-relation_firstname"
                      v-model="data.loanApplicationRelation.firstname"
                      required
                      placeholder="Нэр"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Регистер:" label-for="input-change-relation_register_number">
                  <b-form-input
                      id="input-change-relation_register_number"
                      v-model="data.loanApplicationRelation.register_number"
                      required
                      placeholder="Нэр"
                      disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
          </b-form>
        </b-card-body>
        </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex'
export default {
  name: "Auto.loanRequest.detail",
  components: { axios },
  computed: {
    ...mapGetters([
      'email'
    ])
  },
  data: function() {
    return {
      isLoadingFile: false,
      id: this.$route.params.id,
      data: null,
      json: {},
      isLoading: false,
      form: {
        description: ""
      },
      status_list: [],
      status: null,
      description: "",
      a: 0,
      file: null,
      file_link: null,
      file_message: null
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    id: {
      handler(id) {
        this.loadData();
      },
      deep: true
    }
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;

      this.$http
        .get(this.$config.APP_NUMUR_URL + "api/get_status_list", {
          params: { id: this.$route.params.id },
          emulateJSON: true
        })
        .then(
          (response) => {
            console.log(response);
            this.$data.status_list = response.data.data;

            // this.$data.status = response.data.status;
            // this.$data.id = response.data.id;
            this.loadView();
            this.isLoading = false;
            // this.getMeetNote();
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    loadView: function() {
      this.$http
        .get(this.$config.APP_NUMUR_URL + "api/get_loan_application", {
          params: { id: this.$route.params.id },
          emulateJSON: true
        })
        .then(
          (response) => {
            // //console.logresponse);
            this.$data.data = response.data.data;
            this.isLoading = false;
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    changeStatus: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.APP_NUMUR_URL + "api/change_status", {
          params: {
            id: this.$data.id,
            status: this.$data.status,
            desc: this.$data.description,
            email: this.email
          },
          emulateJSON: true
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
          },
          (response) => {
            // //console.logresponse);
            this.isLoading = false;
          }
        );
    },
    downloadFile(data) {
      window.open( this.$config.APP_NUMUR_S3_URL + data, '_blank');
    }
  }
};
</script>
